<template>
  <b-row align-v="center" align-h="center">
    <b-col cols="6">
      <b-card v-if="azienda" :title="azienda.ragioneSociale">
        <b-card-sub-title v-if="azienda.subscription">
          <div class="mb-2 mt-4">
            Data sottoscrizione:
            <b-badge
              pill
              variant="light-secondary"
            >
              {{ new Date(azienda.subscription.createdAt).toLocaleString('it-IT', this.dateOptions) }}
            </b-badge>
          </div>
          <div class="my-2">
            Utenti iscritti/massimi:
            <b-badge
              pill
              variant="light-primary"
            >
              {{ [items.length,azienda.subscription.maxUsers].join('/') }}
            </b-badge>
          </div>
          <div class="my-2">
            Scadenza:
            <b-badge
              pill
              variant="light-warning"
            >
              {{ azienda.subscription.exipiresOn }}
            </b-badge>
          </div>
        </b-card-sub-title>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BCardSubTitle, BTable, BBadge, BRow, BCol } from 'bootstrap-vue'

export default {
  data() {
    return {
      azienda: null,
      dateOptions: {  timeStyle:"short", dateStyle: 'medium' },
      fields: [
        // 'index',
        {
          key: 'fullname',
          label: 'Nome completo'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'createdAt',
          label: 'Creato',
          formatter: value => new Date(value).toLocaleString('it-IT', this.dateOptions)
        },
        {
          key: 'activatedAt',
          label: 'Attivato',
          formatter: value => value ? new Date(value).toLocaleString('it-IT', this.dateOptions) : ''
        },
      ],
      items: [],
    }
  },
  components: {
    BCard,
    BCardSubTitle,
    BTable,
    BBadge,
    BRow,
    BCol,
  },
  mounted() {
    this.getAzienda()
    this.getUsers()
  },
  methods: {
    getAzienda() {
      this.$http.get('/az/getDetails').then(res => {
        // console.log('AZIENDA ',res)
        this.azienda = res.data
      })
    },
    getUsers() {
      this.$http.get('/az/getAllUsers').then(res => {
        // console.log('Users ',res)
        this.items = res.data.map( u => {
          u['name'] = u.userDetail.name
          u['surname'] = u.userDetail.surname
          u['fullname'] = u.name ? u.name : ''+' '+u.surname ? u.surname : ''
          return u
        })
      })
    },
  }
}
</script>

<style>

</style>
